import React from "react";
import dots from "../assets/images/dots.png";
import ximg from "../assets/images/x.png";
import timg from "../assets/images/t.png";
import oimg from "../assets/images/o.png";
import rimg from "../assets/images/r.png";
import { useLocation } from "react-router-dom";

const CommonHeader = ({ title, subTitle, src, alt, children }) => {
  const location = useLocation();
  return (
    <div className="w-full flex flex-col">
      <div className="w-full relative bg-primary py-40 overflow-hidden h-64">
        <div className="container relative z-10 mx-auto px-4">
          <div className="text-white text-center mb-8">
            <h1 className="font-bold font-heading text-4xl md:text-6xl mt-3 mb-5 capitalize">
              {title}
            </h1>
            <h3
              className={`${
                location.pathname !== "/portfolio" ? "hidden md:block" : "block"
              } bg-clip-text  text-white text-sm md:text-2xl font-extrabold tracking-widest mt-4 uppercase mx-auto`}
            >
              {subTitle}
            </h3>
          </div>
        </div>

        {/* dots  */}
        <div className="w-full">
          <div className="absolute top-1/2 left-1/4 animate-grow z-0">
            <img
              src={dots}
              alt="Decorative dots"
              className="max-w-full h-auto"
            />
          </div>
          <div className="absolute top-12 left-1/2 animate-spin-slow z-0">
            <img src={timg} alt="Letter T" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-0 right-1/3 animate-spin animate-movearound z-0">
            <img src={ximg} alt="Letter X" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-1/3 left-10 animate-spin-slow z-0">
            <img src={rimg} alt="Letter R" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-20 left-1/3 animate-movearound z-0">
            <img src={oimg} alt="Letter O" className="max-w-full h-auto" />
          </div>
          <div className="absolute top-1/2 right-20 animate-spin-slow z-0">
            <img src={timg} alt="Letter T" className="max-w-full h-auto" />
          </div>

          <img
            src={src}
            alt={alt}
            className="absolute top-1/2 left-1/2 scale-250 -translate-x-1/2 -translate-y-1/2 opacity-10 w-4/6 md:w-2/5 max-h-96 w-30rem"
          />
        </div>
      </div>

      {children}
    </div>
  );
};

export default CommonHeader;
