import React from "react";

function ServiceCard({ index, image, content }) {
  return (
    <div
      style={{ animationDelay: index / 9 + "s" }}
      className="service relative group text-center opacity-0 px-8 pt-10 
            pb-14 border md:border rounded-lg shadow-md h-auto"
    >
      {index === 1 || index === 2 ? (
        <div className="relative z-20 flex items-center justify-center -top-5 bg-gradient-to-bl mx-auto rounded-2xl w-52 h-36">
          <img src={image} alt={content.title} className="w-52" />
        </div>
      ) : (
        <div className="relative z-20 flex items-center justify-center -top-5 bg-gradient-to-bl mx-auto rounded-2xl w-36 h-36">
          <img src={image} alt={content.title} className="w-36" />
        </div>
      )}

      <p
        className="relative group-hover:text-white group-focus:text-white transition-colors duration-300 
                text-gray-700 z-10 font-bold font-heading text-xl"
      >
        {content.title}
      </p>
      <p
        className="relative group-hover:text-white group-focus:text-white text-gray-600 font-light font-body transition-colors 
                z-10 duration-300 mt-2"
      >
        {content.description}
      </p>
      <div className="absolute group overflow-hidden top-0 left-0 w-full h-full rounded-lg">
        <div
          className="absolute group-hover:translate-x-0 group-focus:translate-x-0 
                    transition-transform duration-850 top-0 right-0 w-full h-full bg-cloud bg-no-repeat
                     bg-cover -translate-x-105 z-0"
        ></div>
        <div className="absolute group-hover:translate-x-0 group-focus:translate-x-0 transition-transform duration-700 inline-block top-0 left-0 w-full h-full bg-gradient-to-r from-primary to-primary translate-x-full -z-10"></div>
      </div>
    </div>
  );
}

export default ServiceCard;
