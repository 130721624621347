import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import promoVideo from "../assets/videos/promo_video.mp4";
import img from "../assets/images/home.png";

const classes = [
  "animate-appearup",
  "animate-appearup-2",
  "animate-moveleft",
  "animate-appearup",
];

function MainBody() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".main-header");
    const img = document.querySelector(".main-img");
    const video = document.getElementById("videoId");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((ele, i) => {
        ele.target.classList.add(classes[i]);
      });
    });
    cards.forEach((card) => {
      observer.observe(card);
    });
    observer.observe(img);

    const handleVideoLoad = () => {
      setTimeout(() => {
        video.classList.add("loaded");
      }, 10000);
    };
    video.addEventListener("canplay", handleVideoLoad);

    return () => {
      cards.forEach((card) => {
        observer.disconnect(card);
      });
      video.removeEventListener("canplay", handleVideoLoad);
    };
  }, []);

  useEffect(() => {
    setIsImageLoaded(true);
  }, []);

  return (
    <section className="relative bg-main bg-cover min-h-screen pb-20 md:pb-0 flex justify-center items-center overflow-hidden">
      <div className="video-container">
        <img
          src={img}
          alt="landing page background"
          title="landing page background"
          className={`video-bg ${isImageLoaded ? "loaded" : ""}`}
        />
        <video autoPlay muted loop playsInline id="videoId">
          <source src={promoVideo} type="video/mp4" />
        </video>
      </div>
      <div className="absolute z0 p-6 text-whitepb-20 md:pb-0">
        <div className="relative z-10 md:px-20 mt-32 md:mt-0">
          <div className="container mx-auto flex flex-col md:flex-row items-center">
            <div className="flex-1  px-6 md:px-0">
              <h1 className="main-header opacity-0 font-bold font-heading text-1xl text-center md:text-left md:text-4xl mb-6 text-white hidden">
                Welcome to Mereb Technologies
              </h1>
              <h2 className="main-header opacity-0 font-bold font-heading text-3xl text-center md:text-left md:text-5xl mb-6 text-white">
                Your software is as good as the people behind it
              </h2>
              <h3 className="main-header-p opacity-1 font-light font-body text-lg md:text-1xl text-center md:text-left text-white">
                With the synergy of Excellent communication, Experienced
                Engineers, and Latest technology trends, we will provide you
                with the best quality services and products.
              </h3>
              <div className="flex flex-wrap font-extrabold  mt-14 mb-8 text-center md:text-left">
                <Link
                  to="/contact"
                  className="mr-6 py-4 px-7 rounded-md bg-merebBrandColor text-white hover:bg-primaryMedium whitespace-nowrap transition-colors"
                >
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="mx-6 md:mx-0 mt-6 md:mt-0 flex-1 main-img opacity-0"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainBody;
