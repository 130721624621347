import React from "react";

function ProjectDetails({ project }) {
  return (
    <div className="container mx-auto px-10 py-16">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 lg:gap-8">
        <img
          src={project.images[0]}
          alt={project.title}
          className="animate-moveup w-full opacity-0"
        />
        <img
          style={{ animationDelay: ".6s" }}
          src={project.images[1]}
          alt=""
          className="animate-moveup w-full opacity-0"
        />
      </div>
      <div className="mt-10">
        <h1 className="text-indigo-950 font-heading font-extrabold text-3xl mb-3">
          Project Summary
        </h1>
        {project.summary.map((sum, i) => (
          <p key={i} className="text-gray-500 mb-4">
            {sum}
          </p>
        ))}
      </div>
      <div className="py-4 flex flex-col lg:flex-row items-center">
        <img
          src={project.images[2]}
          alt={project.title}
          className="w-full lg:w-1/4"
        />
        <div className="ml-0 lg:ml-8">
          <h1 className="text-indigo-950 font-heading font-extrabold text-2xl mb-2 mt-4 lg:mt-0">
            Result
          </h1>
          {project.result.map((res, i) => (
            <p key={i} className="text-gray-500 mb-4">
              {res}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
