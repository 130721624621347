import React, { useState, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Navbar, Footer } from "./components";
import { useCookies } from "react-cookie";
import { useRive } from "rive-react";
import "./index.css";
import DetailedJobView from "./pages/DetailedJobView";

const AboutPage = lazy(() => import("./pages/AboutPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const LandingPage = lazy(() => import("./pages/LandingPage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const PortfolioPage = lazy(() => import("./pages/PortfolioPage"));
const PrivacyPage = lazy(() => import("./pages/PrivacyPage"));
const BlogsPage = lazy(() => import("./pages/BlogsPage"));
const BlogDetail = lazy(() => import("./pages/BlogDetailPage"));
const OurTeam = lazy(() => import("./pages/OurTeamPage"));
const JobApplicationPage = lazy(() => import("./pages/JobApplicationPage"));
// const DetailsPage = lazy(() => import("./pages/DetailsPage"));

const useCookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookie"]);
  const hasConsented = !!cookies.cookie;

  const onAccept = () => {
    setCookie("cookie", "cookie", { path: "/" });
  };

  return { hasConsented, onAccept };
};

function App() {
  const [loading, setLoading] = useState(true);
  const { hasConsented, onAccept } = useCookieConsent();

  const { RiveComponent } = useRive({
    src: "./merebpreloaderanimation.riv",
    autoplay: true,
    onStop: () => setLoading(false),
    onError: (error) => {
      setLoading(false);
    },
  });
  // if (loading) {
  //   return (
  //     <div
  //       style={{ background: "#091E31" }}
  //       className="min-h-screen flex justify-center items-center"
  //     >
  //       <RiveComponent width={90} height={90} />
  //     </div>
  //   );
  // }

  return (
    <div>
      <Navbar />
      <div className="min-h-screen">
        <Suspense fallback={<div className=""></div>}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="portfolio" element={<PortfolioPage />} />
            {/* <Route path="portfolio/*" element={<DetailsPage />} /> */}
            <Route path="privacy" element={<PrivacyPage />} />
            <Route
              path="author/merebtech"
              element={<Navigate replace to="/" />}
            />
            <Route path="our-team" element={<OurTeam />} />
            <Route path="insights" element={<BlogsPage />} />
            <Route path="/insights/:id" element={<BlogDetail />} />
            <Route path="jobs" element={<JobApplicationPage />} />
            <Route path="/jobs/:id" element={<DetailedJobView />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </div>
      {!hasConsented && (
        <div className="animate-cookie ">
          <div className="CookieConsent bg-primary">
            <div className="customCookie container mx-auto flex flex-col justify-center items-center py-4 px-4 md:flex-row">
              <div>
                <p className="px-4">
                  Mereb Technologies uses cookies to provide enhanced website
                  functionality and experience. By using our website, you agree
                  to
                  <a
                    className="mx-2 text-blue-900 hover:text-gray-400 transition-colors cursor-default text-center md:text-left w-full"
                    href="/privacy"
                  >
                    our privacy notice.
                  </a>
                </p>
              </div>
              <div>
                <button
                  id="rcc-confirm-button"
                  aria-label="Accept cookies"
                  type="button"
                  onClick={onAccept}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default App;
