import React, { useState } from "react";
import office from "../assets/teams/mereb_group_p.png";
import meeting from "../assets/images/meeting.png";
import chip from "../assets/images/chip.png";
import like from "../assets/images/like.png";
import best from "../assets/images/best.png";
import commitment from "../assets/images/commitment.png";

function Accordion({ classes }) {
  const [index, setIndex] = useState(1);

  return (
    <section className={`acc-cont ${classes || ""}`}>
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-8">
          <h2 className="text-primary text-3xl md:text-5xl font-heading">
            Why Mereb
          </h2>
        </div>
        <div className="container  mx-auto px-4 flex flex-col md:flex-row py-8 justify-evenly items-center">
          <div className="relative group w-full md:w-2/5 ">
            <div className="invisible md:visible transition-transform duration-300 group-hover:-translate-x-1.5 group-hover:-translate-y-2.5 absolute -top-8 -left-8 bg-black w-3/5 h-20 rounded" />
            <div className="invisible md:visible transition-transform duration-300 group-hover:translate-x-1.5 group-hover:translate-y-2.5 absolute -bottom-8 -right-8 bg-primary w-3/5 h-20 rounded" />

            <img
              src={office}
              alt="a"
              className=" select-none rounded-lg shadow-lg relative w-full self-center"
            />
          </div>
          <ul className="list-none mt-14 md:mt-0 md:w-2/5">
            <li
              onKeyDown={() => indexHandler(1)}
              onClick={() => indexHandler(1)}
              className={`${
                index === 1 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 1
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={commitment} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    Long-term Commitment
                  </h2>
                </div>
                <div
                  className={`${
                    index === 1
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 1 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}
              >
                We believe meaningful value can be provided through long-term
                commitment. We don’t want to be an extension of your team, we
                strive to be a part of it until your vision becomes reality.
              </div>
            </li>
            <li
              onKeyDown={() => indexHandler(2)}
              onClick={() => indexHandler(2)}
              className={`${
                index === 2 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 2
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={meeting} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    Great Understanding
                  </h2>
                </div>
                <div
                  className={`${
                    index === 2
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 2 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}
              >
                Communication is the core of any product success. We provide
                clear communication about product features, progress of
                development and blocking issues in a digestible manner.
              </div>
            </li>
            <li
              onKeyDown={() => indexHandler(3)}
              onClick={() => indexHandler(3)}
              className={`${
                index === 3 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 3
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={chip} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    Updated Technology
                  </h2>
                </div>
                <div
                  className={`${
                    index === 3
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 3 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}
              >
                Digital technology is always at a rapid pace. Your product gets
                obsolete fast if your foundation is not based on updated
                technology.
              </div>
            </li>
            <li
              onKeyDown={() => indexHandler(4)}
              onClick={() => indexHandler(4)}
              className={`${
                index === 4 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 4
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={like} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    Experienced Team
                  </h2>
                </div>
                <div
                  className={`${
                    index === 4
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 4 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}
              >
                We will tell you again and again. A product is as great as the
                people behind it. Our engineering team has a lot of experience,
                helping you avoid common pitfalls and guiding you to be on the
                right path on each step.
              </div>
            </li>
            <li
              onKeyDown={() => indexHandler(5)}
              onClick={() => indexHandler(5)}
              className={`${
                index === 5 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 5
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={best} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    Best Quality Service
                  </h2>
                </div>
                <div
                  className={`${
                    index === 5
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 5 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 
                            transition-all duration-500 text-gray-800`}
              >
                We don’t just want to create your product as per your request.
                We want to create the best version of the product as humanly
                possible. We want you to collaborate with us again for another
                product.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );

  function indexHandler(i) {
    const j = (index && i) === index ? -1 : i;
    setIndex(j);
  }
}

export default Accordion;
