import React, { useLayoutEffect } from "react";
import ServiceCard from "./ServiceCard";
import s3 from "../assets/images/s33.png";
import s4 from "../assets/images/s44.png";
import s11 from "../assets/images/s111.png";
import s22 from "../assets/images/s22.png";

function Services() {
  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".service");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((ele) => {
        if (ele.isIntersecting) {
          ele.target.classList.add("animate-moveup");
        }
      });
    });
    cards.forEach((card) => {
      observer.observe(card);
    });
    return () => {
      cards.forEach((card) => {
        observer.disconnect(card);
      });
    };
  }, []);
  const contents = [s22, s11, s3, s4];
  const contentDetails = [
    {
      title: "Software Development",
      image: s22,
      description:
        "We offer comprehensive software development services, meticulously crafted to meet the diverse needs of our clients. Our expertise spans various technologies and industries, ensuring that our solutions are not only innovative but also aligned with the latest industry trends.",
    },
    {
      title: "Talent Outsourcing",
      image: s11,
      description:
        "Our talent outsourcing services provide businesses with access to top-tier IT professionals, ready to support projects and operations. We prioritize matching our talent with your business goals, ensuring a seamless integration into your existing teams.",
    },

    {
      title: "Custom Solutions",
      image: s3,
      description:
        "At Mereb Technologies, we specialize in creating custom software solutions that address specific business challenges and opportunities. Our collaborative approach ensures that we deeply understand our client's needs, delivering solutions that drive efficiency and foster growth.",
    },
    {
      title: "Maintenance and Support",
      image: s4,
      description:
        "We provide comprehensive maintenance and support services, ensuring that your software solutions run smoothly and remain up-to-date. Our proactive approach minimizes downtime and enhances system reliability.",
    },
  ];

  return (
    <section className="lg:px-20 my-16 md:my-16">
      <div className="container mx-auto">
        <div className="text-center mb-10">
          <h5 className="text-primary  text-2xl md:text-4xl mt-1 font-heading font-bold flex items-center justify-center">
            Our Services
          </h5>
          <p className="text-gray-700 max-w-3xl mx-auto"></p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-7 md:gap-8">
          {contents.map((x, i) => (
            <ServiceCard
              key={i}
              index={i + 1}
              image={x}
              content={contentDetails[i]}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
